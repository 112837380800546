import React, { useState } from 'react';
import { Box, Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import Header from './Header';
import Comic from './Comic';

// number of chapters and maximum page count for each
const MAX_PAGES = [ 4, 4, 4 ];

const Comics = ({ isMobile }) => {
	const [ chapter, setChapter ] = useState(0);
	const [ page, setPage ] = useState(0);

	const onPageBack = () => {
		if (page > 0) {
			setPage(page - 1);
		} else if (chapter > 0) {
			setPage(MAX_PAGES[chapter - 1] - 1);
			setChapter(chapter - 1);
		}
	};

	const onPageNext = () => {
		if (page < MAX_PAGES[chapter] - 1) {
			setPage(page + 1);
		} else if (chapter < MAX_PAGES.length - 1) {
			setPage(0);
			setChapter(chapter + 1);
		}
	};

	const allowBack = chapter !== 0 || page !== 0;
	const allowNext = chapter < MAX_PAGES.length - 1 || page < MAX_PAGES[chapter] - 1;

	const body = isMobile ? (
		<>
			<Header isMobile={isMobile} onPageBack={onPageBack} onPageNext={onPageNext} allowBack={allowBack} allowNext={allowNext}/>
			<Comic isMobile={isMobile} comic='kts_galactic_adventure' chapter={chapter} page={page}/>
		</>
	) : (
		<Container>
			<Header isMobile={isMobile} onPageBack={onPageBack} onPageNext={onPageNext} allowBack={allowBack} allowNext={allowNext}/>
			<Comic isMobile={isMobile} comic='kts_galactic_adventure' chapter={chapter} page={page}/>
		</Container>
	);

	const style = isMobile ? {
		backgroundColor: 'rgba(52, 35, 69, 1)',
	} : {
		backgroundImage: 'url(/comics/space_background.jpg)',
	};

	return (
		<Box py={isMobile ? 1 : 2} style={style}>
			{body}
		</Box>
	);
};

Comics.propTypes = {
	isMobile: PropTypes.bool.isRequired,
};

export default Comics;
