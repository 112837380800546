import React from 'react';
import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import VideoPreview from '../VideoPreview';
import useVideos from '../useVideos';

const RelatedVideos = ({ creator, sample }) => {
	const { videos } = useVideos({ creator, sample });
	return (
		<Box py={3}>
			<Grid container direction='row' spacing={1}>
				{videos && videos.map(video => (
					<Grid item key={video._id} xs={3}>
						<VideoPreview video={video}/>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

RelatedVideos.propTypes = {
	creator: PropTypes.string.isRequired,
	sample: PropTypes.number.isRequired,
};

export default RelatedVideos;
