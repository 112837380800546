import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import KT from './KT.png';

const Loading = () => {
	return (
		<Container maxWidth="sm" style={{ height: '100%' }}>
			<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
				<img alt="kidtube logo" src={KT} width="80%"/>
				<CircularProgress/>
			</Box>
		</Container>
	);
};

export default Loading;