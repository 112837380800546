import React, { useState } from 'react';
import {
	Paper,
	Box,
	Grid,
	TextField,
	Button,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import KidTubeAPI from '../../kidtubeapi';
import Auth from '../../Auth';

const inputProps = {
	autoCorrect: 'off',
	autoCapitalize: 'off',
	spellCheck: false,
};

const ChangePassword = () => {
	const theme = useTheme();
	const history = useHistory();
	const [ currentPassword, setCurrentPassword ] = useState('');
	const [ newPassword, setNewPassword ] = useState('');

	const disabled = currentPassword === '' || newPassword.length < 8 || currentPassword === newPassword;

	const changePassword = () => {
		if (!currentPassword || typeof currentPassword !== 'string') {
			console.log('Invalid current password: ', currentPassword);
			return;
		}

		if (!newPassword || typeof newPassword !== 'string') {
			console.log('Invalid new password: ', newPassword);
			return;
		}

		KidTubeAPI.changePassword(currentPassword, newPassword)
			.then(() => {
			// display success
				toast.success('Password changed! Please re-login.');

				// clear password inputs
				setCurrentPassword('');
				setNewPassword('');

				// sign out
				Auth.signOut();

				// redirect to login (after 5 seconds)
				setTimeout(() => {
					history.push('/login');
				}, 5000);
			})
			.catch(err => {
			// display error
				toast.error('Unable to change password');
				console.error({ err });
			});
	};

	return (
		<Paper variant="outlined" style={{ backgroundColor: theme.palette.grey['100'] }}>
			<Box p={2}>
				<Grid container direction="row" justify="center">
					<Grid item xs={12} sm={10} md={6} lg={4}>
						<Grid container direction="column" spacing={2}>
							<Grid item>
								<TextField
									variant="outlined"
									fullWidth
									label="Current Password"
									InputProps={inputProps}
									onChange={evt => setCurrentPassword(evt.target.value)}
									value={currentPassword}
								/>
							</Grid>
							<Grid item>
								<TextField
									variant="outlined"
									fullWidth
									label="New Password"
									InputProps={inputProps}
									onChange={evt => setNewPassword(evt.target.value)}
									value={newPassword}
									helperText="Minimum length of 8 characters"
								/>
							</Grid>
							<Grid item>
								<Button fullWidth variant="contained" color="primary" onClick={changePassword} disabled={disabled}>Change Password</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};

export default ChangePassword;
