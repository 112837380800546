import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, CircularProgress, Button, Box, Card, CardContent, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import KidTubeAPI from '../../kidtubeapi';
import useVideos from '../useVideos';
import RelatedVideos from './RelatedVideos';

const { REACT_APP_RECORD_WATCH, REACT_APP_MEDIA_URL } = process.env;
const MIN_PERCENT_TO_WATCH = 0.25;

const computeProgress = ({ played, duration }) => {
	let totalSecondsWatched = 0;
	for (let index = 0; index < played.length; index++) {
		totalSecondsWatched += Math.abs(played.end(index) - played.start(index));
	}
	return totalSecondsWatched / duration;
};

const Watch = () => {
	const { _id } = useParams();
	const history = useHistory();
	const { videos } = useVideos({ _id });
	const video = videos ? videos[0] : null;
	let watched = false;
	let ref = null;
	let player = null;

	useEffect(() => {
		KidTubeAPI.recordVideoClick(_id)
			.catch(error => {
				console.log('error = ', error);
				switch (error.message) {
					case 401:
					case 403:
						history.replace('/register');
						break;
					default:
						console.log('unknown error recording video click. ask to login?');
						history.replace('/login');
						break;
				}
			});
	}, [ _id, history ]);

	const onProgress = () => {
		if (player == null) {
			const internalPlayer = ref.getInternalPlayer();
			if (internalPlayer) {
				player = internalPlayer;
			}
		} else {
			const { played, duration } = player;
			const progress = computeProgress({ played, duration });
			if (REACT_APP_RECORD_WATCH === '1' && watched === false && progress >= MIN_PERCENT_TO_WATCH) {
				// record watch
				watched = true;
				KidTubeAPI.watchVideo(_id).catch(error => console.log('unable to record video watch: ', error));
			}
		}
	};

	const onContextMenu = e => e.preventDefault();

	const handleError = e => console.log('error with video: ', e);

	if (!video) {
		return <Loading/>;
	}

	const { key, title, description, user, creator, version, thumbnail } = video;
	const { username } = creator[0];

	const url = `${REACT_APP_MEDIA_URL}/${key}_${version}/hls/${key}_${version}.m3u8`;
	const thumbnailNumber = thumbnail != null && thumbnail >= 0 ? String(thumbnail).padStart(7,'0') : '0000001';
	const imageSource = `${REACT_APP_MEDIA_URL}/${key}_${version}/thumbnails/${key}_${version}_tumb.${thumbnailNumber}.jpg`;
	return (
		<Container maxWidth='md'>
			<Card>
				<CardContent>
					<Button onClick={() => history.goBack()} style={{ paddingLeft: 0 }}><ArrowBack/> Return</Button>
					<Typography variant="h5" color="primary" gutterBottom>{title}</Typography>
					<ReactPlayer
						url={url}
						controls
						playing
						light={imageSource}
						onContextMenu={onContextMenu}
						onError={handleError}
						onProgress={onProgress}
						width="100%"
						ref={r => ref = r}
					/>
					<Box pt={1}>
						<Creator creator={username}/>
					</Box>
					<Description description={description}/>
				</CardContent>
			</Card>
			<RelatedVideos creator={user} sample={8}/>
		</Container>
	);
};

const Loading = () => (
	<Box mx="auto" pt={10} mt={10} textAlign="center">
		<CircularProgress />
	</Box>
);

const Creator = ({ creator }) => {
	if (!creator) {
		return null;
	}
	return (
		<>
			<Typography variant="overline" color="secondary">Creator</Typography>
			<Typography variant="h3" color="primary">{creator}</Typography>
		</>
	);
};

Creator.propTypes = {
	creator: PropTypes.string.isRequired,
};

const Description = ({ description }) => (
	<>
		<Typography variant="overline" color="secondary">Description</Typography>
		<Typography variant="body1" component="pre" color="primary" style={{ whiteSpace: 'pre-wrap' }}>{description}</Typography>
	</>
);

Description.propTypes = {
	description: PropTypes.string.isRequired,
};

export default Watch;
