import React from 'react';
import { Drawer, Box, List, ListItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useCategories from './useCategories';

export const DRAWER_WIDTH = 300;

const SideDrawer = ({ isMobile, open, toggleDrawer }) => {
	const history = useHistory();

	const categories = useCategories();

	const navigate = path => {
		history.push(path);
		if (isMobile) {
			toggleDrawer();
		}
	};

	const determineURL = category_id => category_id === '6223c335a4b7cabf18c9fb78' ? '/games' : `/videos?category=${category_id}`;

	return (
		<Drawer
			anchor="left"
			variant={isMobile ? 'temporary' : 'persistent'}
			open={open}
			onClose={toggleDrawer}
		>
			<Box width={DRAWER_WIDTH} pt={isMobile ? 0 : 10}>
				<List disablePadding dense>
					{categories && categories.map(category => (
						<ListItem disableGutters key={category._id} button onClick={()=>navigate(determineURL(category._id))} style={{ padding:0 }}>
							<img alt={category.name} src={`/categories/${category._id}.jpg`} width="100%"/>
						</ListItem>
					))}
				</List>
			</Box>
		</Drawer>
	);
};

SideDrawer.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	open: PropTypes.bool.isRequired,
	toggleDrawer: PropTypes.func.isRequired,
};

export default SideDrawer;
