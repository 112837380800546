import React from 'react';
import { Box, Chip } from '@material-ui/core';
import { Mail as MailIcon, ExitToApp as ExitToAppIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Auth from '../../Auth';

const User = ({ email }) => {
	const history = useHistory();

	const signOut = () => {
		Auth.signOut();
		history.push('/login');
	};

	return (
		<Box display="flex" alignItems="center" justifyContent="space-between">
			<Chip icon={<MailIcon/>} label={email}/>
			<Chip icon={<ExitToAppIcon/>} label="Sign Out" onClick={signOut}/>
		</Box>
	);
};

User.propTypes = {
	email: PropTypes.string.isRequired,
};

export default User;
