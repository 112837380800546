import React, { useState } from 'react';
import { Box, Container } from '@material-ui/core';
import logo from '../logo_black_text.png';
import Login from './Login';
import ResetPassword from './ResetPassword';

const LoginPage = () => {
	const [ showLogin, setShowLogin ] = useState(true);

	return (
		<Container maxWidth="sm" style={{ height: '100%' }}>
			<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
				<img alt="kidtube logo" src={logo} width="80%"/>
				{showLogin ? <Login onResetPassword={() => setShowLogin(false)}/> : <ResetPassword onLogin={() => setShowLogin(true)} />}
			</Box>
		</Container>
	);
};

export default LoginPage;
