import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { isSafari, isMobileSafari } from 'react-device-detect';

const { REACT_APP_GAME_URL } = process.env;

const Games = () => {
	if (isSafari || isMobileSafari) {
		return (
			<Box py={5} textAlign="center">
				<Typography variant="h5" style={{ 'fontFamily':'Arial' }} gutterBottom>KT&apos;s K9 Adventure is not playable on this browser.</Typography>
				<Typography variant="h5" style={{ 'fontFamily':'Arial' }}>Please use Google Chrome or Mozilla Firefox.</Typography>
			</Box>
		);
	}

	return (
		<object data={REACT_APP_GAME_URL} style={{ 'height':'85vh' }} width="100%">
			Error: Embedded data could not be displayed. Please try again on Google Chrome or Mozilla Firefox
		</object>
	);
};

export default Games;
