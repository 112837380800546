import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Auth from './Auth';
import Landing from './Public/Landing';
import Loading from './Public/Loading';

const AuthRoute = ({ children, ...rest }) => {
	const [ isAuthenticated, setAuthenticated ] = useState(null);

	useEffect(() => {
		Auth.validate()
			.then(setAuthenticated)
			.catch(error => {
				console.error(error);
				setAuthenticated(false);
			});
	}, [ setAuthenticated ]);

	if (isAuthenticated == null) {
		return <Loading/>;
	}

	return (
		<Route {...rest} render={() => isAuthenticated ? children : <Landing/>}/>
	);
};

AuthRoute.propTypes = {
	children: PropTypes.object.isRequired,
};

export default AuthRoute;
