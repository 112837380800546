import React from 'react';
import { Box, Container, Paper, Typography } from '@material-ui/core';
import kt from './KT.png';

const VerifyNoCode = () => (
	<Container maxWidth="md">
		<Box my={3}>
			<Paper variant="outlined">
				<Box m={3} textAlign="center">
					<img alt="logo" src={kt} height="150px"/>
					<Typography variant="h4">Check your email for a verification link</Typography>
				</Box>
			</Paper>
		</Box>
	</Container>
);

export default VerifyNoCode;
