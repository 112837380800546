import React, { useState, useEffect } from 'react';
import {
	Container,
	Grid,
	Box,
	Typography,
	TextField,
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';

const { REACT_APP_PAYPAL_CLIENT_ID } = process.env;
const REACT_APP_PLUSH_PRICE = parseFloat(process.env.REACT_APP_PLUSH_PRICE);
const REACT_APP_PLUSH_SHIPPING = parseFloat(process.env.REACT_APP_PLUSH_SHIPPING);
const PAY_PAL_BUTTONS_SELECTOR = 'paybal-buttons';

const Shop = () => {
	const [ quantity, setQuantity ] = useState(1);
	const total = (quantity * (REACT_APP_PLUSH_PRICE + REACT_APP_PLUSH_SHIPPING)).toFixed(2);

	useEffect(() => {
		const script = document.createElement('script');
		script.async = true;
		script.src = `https://www.paypal.com/sdk/js?client-id=${REACT_APP_PAYPAL_CLIENT_ID}`;
		script.onload = () => {
			window.paypal.Buttons({
				style: {
					color: 'blue',
				},
				createOrder: (data, actions) => {
					return actions.order.create({
						purchase_units: [
							{
								amount: {
									value: total,
									description: `KT Plushy (${quantity})`,
								},
								soft_descriptor: 'Plushy',
							},
						],
						application_context: {
							brand_name: 'KidTube',
						},
					});
				},
				onApprove: (data, actions) => {
					return actions.order.capture().then(details => {
						console.log({ details });
					});
				},
				onError: err => {
					console.log(err);
				},
			})
				.render(`#${PAY_PAL_BUTTONS_SELECTOR}`);
		};

		document.body.appendChild(script);
	}, [ total, quantity ]);

	const handleChangeQuantity = value => {
		if (!isNaN(parseInt(value)) && !/^\d+\..*$/.test(value)) {
			setQuantity(value);
		}
		// else ignore any decimal/non numeric values
	};

	const imageContainerStyle = isMobile ? null : {
		height: '600px',
	};

	return (
		<Container>
			<Grid container direction='row' alignItems='center'>
				<Grid item xs={12} md={8} lg={6}>
					<Box textAlign='center' style={imageContainerStyle}>
						<img src='/landing/kt_plushy.jpg' alt='kt plushy' height={isMobile ? '' : '100%'} width={isMobile ? '100%' : ''}/>
					</Box>
				</Grid>
				<Grid item xs={12} md={4} lg={6}>
					<Box display='flex' flexDirection='column'>
						<Typography variant='h2' gutterBottom>KT Plushy</Typography>
						<Typography paragraph>Bring the KT plushy into your life today!</Typography>
						<Typography variant='h5' color='primary' gutterBottom><Typography variant='body1' component='span' color='textSecondary'>Unit Price: </Typography>${REACT_APP_PLUSH_PRICE}</Typography>
						<TextField
							label="Quantity"
							type="number"
							inputProps={{
								min: 1,
								max: 100,
								step: 1,
							}}
							variant="outlined"
							value={quantity}
							fullWidth
							onChange={e => handleChangeQuantity(e.target.value)}
						/>
						<Typography variant='h5' color='primary' gutterBottom><Typography variant='body1' component='span' color='textSecondary'>Shipping: </Typography>${REACT_APP_PLUSH_SHIPPING}</Typography>
						<Typography variant='h3' color='primary' gutterBottom><Typography variant='body1' component='span' color='textSecondary'>Total Price: </Typography>${total}</Typography>
						<Typography variant='body1' color='textSecondary'>Currently shipping anywhere in the U.S.A.!</Typography>
						<Box pt={2}>
							<div id={PAY_PAL_BUTTONS_SELECTOR}/>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Shop;
