import React, { useState } from 'react';
import {
	Container,
	Box,
	Typography,
	TextField,
	FormControl,
	Select,
	MenuItem,
	Checkbox,
	Button,
	Link,
	Divider,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import KidTubeAPI from '../../kidtubeapi';
import LoginLink from '../Login/LoginLink';
import Storage from '../../Storage';

const inputProps = {
	autoCorrect: 'off',
	autoCapitalize: 'off',
	spellCheck: false,
};

const Register = ({ display, onNext }) => {
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ referral, setReferral ] = useState('none');
	const [ referralOption, setReferralOption ] = useState('');
	const [ displayReferralInput, setDisplayReferralInput ] = useState(false);
	const [ error, setError ] = useState(null);
	const [ acceptPolicy, setAcceptPolicy ] = useState(false);

	const handleReferralOptionChange = evt => {
		const { value } = evt.target;

		if (value === 'creator' || value === 'other') {
			// display text input
			setDisplayReferralInput(true);
			if (value === 'creator') {
				setReferral(`creator - ${referral}`);
			} else {
				setReferral('other');
			}
		} else {
			// hide text input, set referral value
			setDisplayReferralInput(false);
			setReferral(value);
		}

		setReferralOption(value);
	};

	const handleReferralChange = value => {
		if (value === '') {
			if (referralOption === 'creator') {
				setReferral('creator - unknown');
			} else {
				setReferral('other');
			}
		} else {
			if (referralOption === 'creator') {
				setReferral(`creator - ${value}`);
			} else {
				setReferral(value);
			}
		}
	};

	const register = () => {
		KidTubeAPI.register({ email, password, referral })
			.then(user => {
				Storage.save({ email, token: user.token });
				onNext();
			})
			.catch(err => {
				console.log('Registration error = ', err);
				let error;
				try {
					error = JSON.parse(err.message).message;
				} catch (parseError) {
					error = err.message;
				}
				setError(error);
			});
	};

	const isEmailValid = email => {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
	};

	const isPasswordValid = pw => pw && pw.length >= 8;

	const allowRegister = isEmailValid(email) && isPasswordValid(password) && acceptPolicy;

	if (!display) {
		return null;
	}

	return (
		<>
			<Container maxWidth="sm">
				<Box textAlign="center" py={3}>
					<Typography variant="body1">
						Create an account to start watching KidTube!
					</Typography>
					<Box m={3}>
						<TextField
							label="Email"
							type="email"
							variant="outlined"
							fullWidth
							InputProps={inputProps}
							onChange={evt => setEmail(evt.target.value)}
						/>
					</Box>
					<Box m={3}>
						<TextField
							label="Password"
							type="string"
							variant="outlined"
							fullWidth
							InputProps={inputProps}
							onChange={evt => setPassword(evt.target.value)}
						/>
					</Box>
					<Box my={5}>
						<FormControl>
							<Typography variant="h6">
								<Typography variant="body1" color="secondary" component="span">Optional</Typography> How did you hear about KidTube?
							</Typography>
							<Select
								value={referralOption}
								onChange={handleReferralOptionChange}
								fullWidth
							>
								<MenuItem value='none' disabled><em>Please select one</em></MenuItem>
								<MenuItem value="facebook">Facebook</MenuItem>
								<MenuItem value="pinterest">Pinterest</MenuItem>
								<MenuItem value="instagram">Instagram</MenuItem>
								<MenuItem value="google_ad">Google Ad</MenuItem>
								<MenuItem value="cool_mom">Cool Mom</MenuItem>
								<MenuItem value="creator">Creator</MenuItem>
								<MenuItem value="other">Other</MenuItem>
							</Select>
							{displayReferralInput &&
								<TextField
									label={referralOption === 'creator' ? 'Creator Name' : 'Please specify'}
									InputProps={inputProps}
									onChange={evt => handleReferralChange(evt.target.value)}
									fullWidth
								/>
							}
						</FormControl>
					</Box>
					<Box>
						<Typography variant="body1">
							I have read and agree with <Link target="_blank" rel="noopener noreferrer" href="/privacy.html">KidTube&apos;s Privacy Practices</Link>
						</Typography>
						<Checkbox
							color="primary"
							checked={acceptPolicy}
							onChange={() => setAcceptPolicy(!acceptPolicy)}
						/>
					</Box>
					{error &&
						<Box my={3}>
							<Typography variant="h6" color="error">{error}</Typography>
						</Box>
					}
					<Box mt={2} textAlign="center">
						<Button
							variant="contained"
							color="primary"
							disabled={!allowRegister}
							onClick={register}
						>
							Create Account
						</Button>
					</Box>
				</Box>
			</Container>
			<Divider/>
			<Box my={3} textAlign="center">
				<Typography variant="h6">Already have an account?</Typography>
				<LoginLink/>
			</Box>
		</>
	);
};

Register.propTypes = {
	display: PropTypes.bool.isRequired,
	onNext: PropTypes.func.isRequired,
};

export default Register;
