import React, { useState } from 'react';
import {
	Paper,
	Box,
	Grid,
	Typography,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import KidTubeAPI from '../../kidtubeapi';
import Auth from '../../Auth';

const { REACT_APP_EMAIL } = process.env;

const DeleteAccount = () => {
	const theme = useTheme();
	const history = useHistory();
	const [ showDialog, setShowDialog ] = useState(false);

	const deleteAccount = () => {
		// close dialog
		setShowDialog(false);

		KidTubeAPI.requestDeletion()
			.then(() => {
				// sign out
				Auth.signOut();

				// redirect to login
				history.push('/login');
			})
			.catch(err => {
				// error while requesting delete account
				console.error('Unable to delete account', { err });
				toast.error(`Our apologies, we were unable to submit your request to delete account. Please email ${REACT_APP_EMAIL}`, { autoClose: false });
			});
	};

	return (
		<Paper variant="outlined" style={{ backgroundColor: theme.palette.grey['100'] }}>
			<Box p={2}>
				<Grid container direction="row" justify="center">
					<Grid item xs={12} sm={10} md={6} lg={4}>
						<Button fullWidth style={{ backgroundColor: theme.palette.error.dark, color: 'white' }} variant="contained" onClick={() => setShowDialog(true)}>
							<Delete style={{ marginRight: 4 }} />
							Delete Account
						</Button>
						<Dialog onClose={() => setShowDialog(false)} open={showDialog}>
							<DialogTitle>Are you sure?</DialogTitle>
							<DialogContent>
								<Typography>Once you delete your account, there is no way to recover it.</Typography>
							</DialogContent>
							<DialogActions>
								<Button color="primary" onClick={() => setShowDialog(false)}>Cancel</Button>
								<Button color="secondary" onClick={deleteAccount}>Yes, delete it</Button>
							</DialogActions>
						</Dialog>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};

export default DeleteAccount;
