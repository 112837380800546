import React from 'react';
import {
	createMuiTheme,
	AppBar,
	Toolbar,
	Container,
	Box,
	Typography,
	Grid,
	Link,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import useCategories from '../Authenticated/useCategories';
import appStoreLogo from '../images/App_Store_Badge.svg';
import googlePlayLogo from '../images/google-play-badge.png';
import ShopModal from './Shop/ShopModal';
import Footer from './Footer';
import LoginLink from './Login/LoginLink';
import RegisterLink from './Registration/RegisterLink';

const theme = createMuiTheme({
	typography: {
		fontFamily: 'Verdana',
	},
	palette: {
		primary: {
			main: '#EE5934',
		},
	},
});

const Landing = () => {
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const categories = useCategories();
	return (
		<ThemeProvider theme={theme}>
			<Header />
			<Banner />
			<AboutUs />
			<Commercial />
			<Standards />
			<Promo />
			<Testimonials smallScreen={smallScreen}/>
			<Collage categories={categories} />
			<AppLinks />
			<Footer />
		</ThemeProvider>
	);
};

const AppLink = ({ larger }) => (
	<Link target="_blank" rel="noopener" href="https://apps.apple.com/us/app/kidtube-com/id1492529380?ls=1">
		<img src={appStoreLogo} alt="App Store logo" width={!isMobile || larger ? '200px' : '120px'} height={!isMobile || larger ? '70px' : '50px'} />
	</Link>
);

AppLink.propTypes = {
	larger: PropTypes.bool,
};

const GooglePlayLink = ({ larger }) => (
	<Link target="_blank" rel="noopener" href="https://play.google.com/store/apps/details?id=com.kidtube.mobile">
		<img src={googlePlayLogo} alt="Google Play Store logo" width={!isMobile || larger ? '200px' : '120px'} height={!isMobile || larger ? '70px' : '50px'} />
	</Link>
);

GooglePlayLink.propTypes = {
	larger: PropTypes.bool,
};

const Header = () => (
	<AppBar position="fixed">
		<Toolbar>
			<Box width={1} p={1} display="flex" alignItems="center" justifyContent="space-between">
				<Box display="flex">
					<LoginLink />
					<Box ml={2}>
						<RegisterLink />
					</Box>
				</Box>
				<Box display="flex" justify="center">
					<ShopModal/>
				</Box>
			</Box>
		</Toolbar>
	</AppBar>
);

const Banner = () => (
	<Box pt={isMobile ? 8 : 10}>
		<img src="/banner.jpg" alt="main" width="100%"/>
	</Box>
);

const AppLinks = () => (
	<Box m={5} textAlign="center">
		<AppLink larger/>
		<GooglePlayLink larger/>
	</Box>
);

const AboutUs = () => (
	<Box mx={1} my={5} textAlign='center'>
		<Typography variant='h3' style={{ fontFamily: 'Helvetica, Arial' }} gutterBottom><i>About Us</i></Typography>
		<Container maxWidth='md'>
			<Typography variant='h6' style={{ fontFamily: 'Helvetica, Arial' }} paragraph>
				Here, at Kidtube, we truly believe in protecting your kids online! How do we do it? Well, we offer parents the confidence in knowing that EVERY single video posted to KidTube has been watched by our team, to ensure that it is free of inappropriate content!
			</Typography>
			<Typography variant='h6' style={{ fontFamily: 'Helvetica, Arial' }}>
				That&apos;s right NO more inappropriate content! Kids stumble upon inappropriate content online every day! Especially on video streaming websites! Sexual content and suggestive behavior are some of the serious problems children are seeing online at a younger age! This needs to STOP! It’s hard for parents to monitor everything their kids are seeing online, so we’ve done it for you! We’ve screened/monitored every single video for YOU and your CHILD! The same videos your kids are used to watching online are now safe on our site!
			</Typography>
		</Container>
	</Box>
);

const Commercial = () => {
	return (
		<Box textAlign="center">
			<Container maxWidth="lg">
				<video controls autoPlay muted width="100%">
					<source src="/commercial.mp4" type="video/mp4"/>
				</video>
			</Container>
		</Box>
	);
};

const Testimonials = ({ smallScreen }) => {
	return (
		<Box my={5} textAlign="center">
			<Container maxWidth="lg">
				<Typography variant="h3" gutterBottom><i>What Parents Are Saying...</i></Typography>
				<Box pt={3}>
					<Grid container spacing={4} align="stretch">
						<Grid item xs={12} md={4}>
							<Grid container direction="column" justify="space-around" alignItems="stretch">
								<Grid item>
									<img src="/landing/1.jpg" alt="main" width="100%"/>
								</Grid>
								<Grid item>
									<Typography variant="h4">Then Came KidTube!</Typography>
									<Typography variant="body2">
										&quot;My kids love watching those blogger family and toy surprise videos, but the site we used to use had too much freedom for them and inappropriate content for them to stumble upon. I wish I had the time to screen every video before they watched it, but with 4 kids that&apos;s near impossible, so we just stopped using the site altogether. Then came KidTube! I love that KidTube has REAL people screening every single video before it is posted and has taken that stress off of this busy mom&apos;s mind! My kids are happy to have their videos back and I&apos;m happy to have peace of mind.&quot;
									</Typography>
									<Typography variant="body2">
										-Michelle, Mom of 4
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={4}>
							<Grid container direction={`column${smallScreen ? '' : '-reverse'}`} justify="space-around" alignItems="stretch">
								<Grid item>
									<img src="/landing/2.jpg" alt="main" width="100%"/>
								</Grid>
								<Grid item>
									<Typography variant="h4">Ad Free!</Typography>
									<Typography variant="body2">
										&quot;My son used to love watching videos on other online video sites, and although the videos he was watching were not bad, I hated that many ads that popped up were often inappropriate for his age. I love that, since the content on KidTube has been reviewed by other moms, I can trust that it is safe for my son to watch, ad free!! And KT is the cutest!&quot;
									</Typography>
									<Typography variant="body2">
										-Lynette, Mom of 2
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={4}>
							<Grid container direction="column" justify="space-around" alignItems="stretch">
								<Grid item>
									<img src="/landing/3.jpg" alt="main" width="100%"/>
								</Grid>
								<Grid item>
									<Typography variant="h4">A Place That Kids Can Truly Be Safe Online!</Typography>
									<Typography variant="body2">
										&quot;I love that KidTube.com is a place that kids can truly be safe online! Carefully selected educational videos and activities that are mom-approved! No commercials, ads, etc.&quot;
									</Typography>
									<Typography variant="body2">
										-Cara, Mom of 2
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</Box>
	);
};

Testimonials.propTypes = {
	smallScreen: PropTypes.bool.isRequired,
};

const Standards = () => (
	<Box my={3} py={4} px={4} bgcolor='grey.300' textAlign='center'>
		<Container style={{ fontFamily: 'Helvetica, Arial' }}>
			<Typography variant='h3' gutterBottom><i>KidTube Standards</i></Typography>
			<Typography variant='h6' paragraph>We DO NOT allow any of the following on our site!</Typography>
			<Box mb={3}>
				<Grid container direction='row' spacing={5} justify='center' alignItems='center'>
					<Grid item>
						<Typography variant='h6'>GRAPHIC VIOLENCE</Typography>
						<Typography variant='h6'>PROFANITY</Typography>
						<Typography variant='h6'>SEXUAL CONTENT</Typography>
						<Typography variant='h6'>NUDITY</Typography>
					</Grid>
					<Grid item>
						<Typography variant='h6'>COMMERCIALS</Typography>
						<Typography variant='h6'>COMMENTS</Typography>
						<Typography variant='h6'>PHISHING</Typography>
						<Typography variant='h6'>ANNOYING POP-UP ADS</Typography>
					</Grid>
				</Grid>
			</Box>
			<Typography variant='h6'>OUR VIDEO CONTENT IS SUGGESTED FOR KIDS AGES 12 AND UNDER</Typography>
			<Box py={2}>
				<Typography variant='h6'>
					<Link href="https://www.kidtubeupload.com">KidTube is always looking for clean, family friendly content. If you would like to become a content creator, sign up here!</Link>
				</Typography>
			</Box>
		</Container>
	</Box>
);

const Promo = () => {
	return (
		<Container maxWidth="lg">
			<img src="/categories/5fbe8e7a4f0b4f75695a10d9.jpg" alt="kt galactic adventure" width="100%"/>
			<video controls autoPlay muted width="100%">
				<source src="/kt_galactic_adventure_trailer.mp4" type="video/mp4"/>
			</video>
		</Container>
	);
};

const Collage = ({ categories }) => (
	<Box my={5} textAlign="center">
		<Container maxWidth="lg">
			<Grid container direction="row">
				{categories && categories.map(category => (
					<Grid key={category._id} item xs={12} sm={6}>
						<img src={`/categories/${category._id}.jpg`} alt={category.name} width="100%"/>
					</Grid>
				))}
			</Grid>
		</Container>
	</Box>
);

Collage.propTypes = {
	categories: PropTypes.arrayOf(PropTypes.shape({ _id: String, name: String })),
};

export default Landing;
