import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Container, Paper, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import KidTubeAPI from '../kidtubeapi';
import kt from './KT.png';

const Verify = () => {
	const { code } = useParams();
	const [ error, setError ] = useState(null);
	const [ verified, setVerified ] = useState(false);

	useEffect(() => {
		KidTubeAPI.verify({ code })
			.then(() => {
				setVerified(true);
			})
			.catch(err => setError(err.message));
	}, [ code ]);

	const status = error ?
		null :
		!verified ?
			<CircularProgress/> :
			(
				<>
					<Typography variant="h3" color="primary">Your email has been verified!</Typography>
					<Typography variant="h6" color="secondary">You can close this window and continue watching KidTube.</Typography>
				</>
			);

	return (
		<Container maxWidth="md">
			<Box my={3}>
				<Paper variant="outlined">
					<Box m={3} textAlign="center">
						<img alt="logo" src={kt} height="150px"/>
						<Typography variant="h6" color="error">{error}</Typography>
						{status}
					</Box>
				</Paper>
			</Box>
		</Container>
	);
};

export default Verify;
