import React from 'react';
import Button from '@material-ui/core/Button';

const LoginLink = () => (
	<Button variant="outlined" size="large" style={{ backgroundColor: '#fff' }} href="/register">Sign Up</Button>
);

export default LoginLink;


