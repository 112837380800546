import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, Paper } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import logo from '../logo_black_text.png';
import Process from './Process';
import Register from './Register';
import Payment from './Payment';

const useQuery = () => new URLSearchParams(useLocation().search);

const Registration = () => {
	const history = useHistory();
	const query = useQuery();
	const _step = parseInt(query.get('step'));
	const [ step, setStep ] = useState(_step || 1);

	useEffect(() => {
		if (!isNaN(_step)) {
			setStep(_step);
		}
	}, [ _step ]);

	const redirect = step => history.push(`/register?step=${step}`);

	const nextStep = () => {
		if (step >= 2) {
			// redirect to home
			history.push('/');
			// setStep(1);
		} else {
			// redirect to next page
			redirect(step+1);
		}
	};

	return (
		<>
			<Box p={2} bgcolor="primary.main" textAlign="center" color="white">
				<Typography variant="h5" style={{ fontFamily:'ComicKings', letterSpacing: 1.5 }}>Start with a 10 day free trial!</Typography>
			</Box>
			<Box pb={5}>
				<Container maxWidth="md">
					<Box my={2} textAlign="center" maxWidth="600px" mx="auto">
						<img src={logo} alt="kidtube logo" width="100%"/>
					</Box>
					<Paper variant="outlined">
						<Process step={step}/>
						<Register
							display={step === 1}
							onNext={nextStep}
						/>
						<Payment
							display={step === 2}
							onNext={nextStep}
							redirect={redirect}
						/>
					</Paper>
				</Container>
			</Box>
		</>
	);
};

export default Registration;
