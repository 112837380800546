import Storage from './Storage';
import KidTubeAPI from './kidtubeapi';

const Auth = {
	signIn: async ({ email, password }) => {
		// send API
		const result = await KidTubeAPI.authenticate({ email, password });

		const { status, token, message } = result;

		if (status === 400) {
			throw Error(message);
		}

		if (status !== 200 && status !== 202) {
			throw Error('Unknown auth error: ', JSON.stringify(result));
		}

		Storage.save({ email, token });
		return { status, message };
	},
	verify: async ({ email, verificationCode }) => {
		const result = await KidTubeAPI.verify({ email, verificationCode });
		const { status, token } = result;

		if (status !== 200) {
			throw Error(result.message);
		} else {
			Storage.save({ email, token });
		}
	},
	resetPassword: async email => {
		return await KidTubeAPI.resetPassword(email);
	},
	isAuthenticated: () => {
		const user = Storage.read();
		if (!user || !user.token) {
			return false;
		}
		return true;
	},
	validate: async () => {
		if (!Auth.isAuthenticated()) {
			return false;
		}

		try {
			return await KidTubeAPI.validate();
		} catch (error) {
			console.log(error);
			Storage.delete();
			return false;
		}
	},
	currentUser: () => {
		return Storage.read();
	},
	token: () => {
		const user = Storage.read();
		return user && user.token ? user.token : null;
	},
	signOut: () => {
		Storage.delete();
	},
};

export default Auth;
