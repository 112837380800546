import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import VideoGroup from './VideoGroup';

const defaultQuery = {
	category: '',
};

const Videos = () => {
	const location = useLocation();
	const history = useHistory();
	const query = queryString.parse(location.search) || defaultQuery;
	const { category, search } = query;
	let skip = parseInt(query.skip);
	if (skip < 0) {
		query.skip = 0;
		history.replace(`/videos?${queryString.stringify(query)}`);
	}
	return (
		<>
			{category &&
			<img alt="category banner" src={`/categories/${category}.jpg`} width="100%"/>
			}
			<VideoGroup search={search} category={category} limit={24} skip={skip || 0}/>
		</>
	);
};

export default Videos;
