import { useState, useEffect } from 'react';
import Auth from '../Auth';
import KidTubeAPI from '../kidtubeapi';

const useVideos = ({ _id, creator, category, search, sample, order, skip, limit }) => {
	const [ videos, setVideos ] = useState(null);
	const [ total, setTotal ] = useState(-1);

	useEffect(() => {
		KidTubeAPI.getVideos({ _id, creator, category, search, sample, order, skip, limit })
			.then(result => {
				const { videos, total } = result[0];
				setVideos(videos);
				setTotal(total && total[0] ? total[0].total : -1);
			})
			.catch(err => {
				console.warn('useVideos(): ', err);
				Auth.signOut();
			});
	}, [ _id, creator, category, search, sample, order, skip, limit ]);

	return {
		videos,
		total,
	};
};

export default useVideos;
