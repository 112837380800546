import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
	typography: {
		fontFamily: 'Comic Sans MS, sans-serif',
		body2: {
			fontFamily: 'Arial',
		},
	},
	palette: {
		primary: {
			main: '#EE5934',
		},
		secondary: {
			main: '#999999',
		},
	},
	zIndex: {
		appBar: 1101,
		drawer: 1100,
	},
});
