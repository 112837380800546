import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Auth from '../../Auth';

const ResetPassword = ({ onLogin }) => {
	const [ email, setEmail ] = useState('');
	const [ complete, setComplete ] = useState(false);
	const [ error, setError ] = useState('');

	const handleEmailChange = evt => setEmail(evt.target.value.toLowerCase());

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	const emailIsValid = email => emailRegex.test(email);

	const resetPassword = () => {
		if (!emailIsValid(email)) {
			setError('Invalid email');
			return;
		}

		Auth.resetPassword(email)
			.then(result => {
				console.log('result = ', result);
				if (result.error) {
					setError(result.error);
					switch (result.error) {
						case 'Email not verified': {
							console.log('TODO: verify email');
							break;
						}
						case 'Invalid subscription': {
							console.log('TODO: ask to pay');
							break;
						}
						default: {
							console.log('other error: ', result.error);
							break;
						}
					}
				} else {
				// on completion
					setComplete(true);
					setError('');
				}
			})
			.catch(err => {
			//TODO display authentication error
				console.log('reset password error = ', err);
			});
	};

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<TextField
					variant="outlined"
					style={{ backgroundColor:'white' }}
					name="email"
					type="email"
					label="Email"
					fullWidth
					onChange={handleEmailChange}
				/>
			</Grid>
			{complete &&
			<Grid item>
				<Typography variant="body2" color="textSecondary">
					If the email exists in our system, you will receive a reset password email in a few moments.
				</Typography>
			</Grid>
			}
			{error &&
				<Grid item>
					<Typography align="center" variant="h6" color="error">{error}</Typography>
				</Grid>
			}
			<Grid item>
				<Box display="flex" flexDirection="row" justifyContent="space-between">
					<Button variant="contained" onClick={onLogin}>Back</Button>
					<Button variant="contained" disabled={complete || !emailIsValid(email)} color="primary" onClick={resetPassword}>Reset Password</Button>
				</Box>
			</Grid>
		</Grid>
	);
};

ResetPassword.propTypes = {
	onLogin: PropTypes.func.isRequired,
};

export default ResetPassword;
