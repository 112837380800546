import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const Header = ({ isMobile, onPageBack, onPageNext, allowBack, allowNext }) => {
	return (
		<>
			<img src='/comics/comics.jpg' alt="logo" width="100%"/>
			<Box
				p={1}
				my={1}
				bgcolor="primary.main"
				border={3}
				borderRadius={10}
				borderColor="white"
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<img src='/comics/back.png' alt="page_back" height={isMobile ? '50px' : '75px'} className={allowBack ? 'clickable' : 'disabled'} onClick={onPageBack}/>
				<img src='/comics/next.png' alt="page_next" height={isMobile ? '50px' : '75px'} className={allowNext ? 'clickable' : 'disabled'} onClick={onPageNext}/>
			</Box>
		</>
	);
};

Header.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	onPageBack: PropTypes.func.isRequired,
	onPageNext: PropTypes.func.isRequired,
	allowBack: PropTypes.bool.isRequired,
	allowNext: PropTypes.bool.isRequired,
};

export default Header;
