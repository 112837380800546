import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { Paper, Box } from '@material-ui/core';
import Auth from '../../Auth';
import Footer from '../../Public/Footer';
import User from './User';
import ChangePassword from './ChangePassword';
import DeleteAccount from './DeleteAccount';

const Profile = () => {
	const [ email, setEmail ] = useState('');

	useEffect(() => {
		// obtain user data
		const { email } = Auth.currentUser();
		setEmail(email);
	}, []);

	return (
		<Container maxWidth="md">
			<Paper>
				<Box py={2} px={3}>
					<Box mb={2}>
						<User email={email}/>
					</Box>
					<Box mb={2}>
						<ChangePassword/>
					</Box>
					<Box mb={2}>
						<DeleteAccount/>
					</Box>
					<Footer/>
				</Box>
			</Paper>
		</Container>
	);
};

export default Profile;
