import React, { useState, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import AuthRoute from '../AuthRoute';
import Shop from '../Public/Shop/Shop';
import Header from './Header';
import SideDrawer, { DRAWER_WIDTH } from './SideDrawer';
import Home from './Home';
import Watch from './Watch/Watch';
import Videos from './Videos';
import Profile from './Profile/Profile';
import Comics from './Comics/Comics';
import Games from './Games';

const MARGIN_TOP = isMobile ? '95px' : '85px';

const App = () => {
	const [ marginLeft, setMarginLeft ] = useState(isMobile ? 0 : DRAWER_WIDTH + 5 + 'px');
	const [ displayDrawer, setDisplayDrawer ] = useState(!isMobile);

	useEffect(() => {
		document.body.style.backgroundColor = '#F0F0F0';
	}, []);

	const toggleDrawer = () => {
		setMarginLeft(!isMobile && !displayDrawer ? DRAWER_WIDTH + 5 + 'px' : 0);
		setDisplayDrawer(!displayDrawer);
	};

	return (
		<>
			<Header toggleDrawer={toggleDrawer}/>
			<SideDrawer isMobile={isMobile} open={displayDrawer} toggleDrawer={toggleDrawer}/>
			<Box mt={MARGIN_TOP} ml={marginLeft} px={isMobile ? 0 : 1}>
				<Switch>
					<AuthRoute exact path='/'>
						<Home/>
					</AuthRoute>
					<AuthRoute path='/watch/:_id'>
						<Watch/>
					</AuthRoute>
					<AuthRoute path='/videos'>
						<Videos/>
					</AuthRoute>
					<AuthRoute path='/profile'>
						<Profile/>
					</AuthRoute>
					<AuthRoute path='/comics'>
						<Comics isMobile={isMobile}/>
					</AuthRoute>
					<AuthRoute path='/games'>
						<Games/>
					</AuthRoute>
					<AuthRoute path="/shop">
						<Shop/>
					</AuthRoute>
				</Switch>
			</Box>
		</>
	);
};

export default App;
