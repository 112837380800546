import React from 'react';
import VideoGroup from './VideoGroup';
import useCategories from './useCategories';

const SAMPLE_CATEGORIES = 4;
const Home = () => {
	const categories = useCategories(SAMPLE_CATEGORIES);
	return (
		<>
			<img alt="home" src="/banner.jpg" width="100%"/>
			{categories && categories.map(category => (
				<VideoGroup key={category._id} category={category} limit={8}/>
			))}
		</>
	);
};

export default Home;
