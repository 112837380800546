import React, { useState, useEffect } from 'react';
import {
	Container,
	Grid,
	Box,
	Typography,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Auth from '../../Auth';
import KidTubeAPI from '../../kidtubeapi';

const {
	REACT_APP_PAYPAL_CLIENT_ID,
	REACT_APP_MONTHLY_AMOUNT,
	REACT_APP_ANNUAL_AMOUNT,
	REACT_APP_TRIAL_DAYS,
	REACT_APP_MONTHLY_PLAN_ID,
	REACT_APP_ANNUAL_PLAN_ID,
} = process.env;

const PAY_PAL_BUTTONS_SELECTOR = 'paypal-buttons';

const Payment = ({ display, onNext, redirect }) => {
	const [ planID, setPlanID ] = useState(REACT_APP_MONTHLY_PLAN_ID);

	useEffect(() => {
		const script = document.createElement('script');
		script.async = true;
		script.src = `https://www.paypal.com/sdk/js?client-id=${REACT_APP_PAYPAL_CLIENT_ID}&vault=true`;
		script.dataset['sdkIntegrationSource'] = 'button-factory';
		script.onload = () => {
			window.paypal.Buttons({
				style: {
					color: 'blue',
				},
				createSubscription: (data, actions) => {
					return actions.subscription.create({
						plan_id: planID,
						application_context: {
							brand_name: 'KidTube',
							shipping_preference: 'NO_SHIPPING',
						},
					});
				},
				onApprove: (data) => {
					const { subscriptionID } = data;
					const type = planID === REACT_APP_MONTHLY_PLAN_ID ? 'monthly' : 'annual';

					// validate
					if (!subscriptionID || !type) {
						toast.error('The payment was approved however, there was an error with our system. Please email contactinfo@kidtube.video to resolve the issue.', { autoClose: false });
					}
					KidTubeAPI.completePayment({ subscriptionID, type })
						.then(() => onNext())
						.catch(err => toast.error(err.message));
				},
			})
				.render(`#${PAY_PAL_BUTTONS_SELECTOR}`);
		};

		document.body.appendChild(script);
	}, [ planID, onNext ]);

	if (!display) {
		return null;
	}

	const user = Auth.currentUser();
	if (!user || !user.email || !user.token) {
		// send to step 1
		redirect(1);
		return null;
	}

	return (
		<Container maxWidth="sm">
			<Box textAlign="center" py={3}>
				<Box pb={3}>
					<Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
						<Grid item>
							<PaymentOption type={REACT_APP_MONTHLY_PLAN_ID} onClick={setPlanID} selectedPayment={planID}/>
						</Grid>
						<Grid item>
							<PaymentOption type={REACT_APP_ANNUAL_PLAN_ID} onClick={setPlanID} selectedPayment={planID}/>
						</Grid>
					</Grid>
				</Box>
				<PaymentText type={planID}/>
				<Box py={1}>
					<div id={PAY_PAL_BUTTONS_SELECTOR}></div>
				</Box>
			</Box>
		</Container>
	);
};

Payment.propTypes = {
	display: PropTypes.bool.isRequired,
	onNext: PropTypes.func.isRequired,
	redirect: PropTypes.func.isRequired,
};

const PaymentOption = ({ type, onClick, selectedPayment }) => {
	const [ hover, setHover ] = useState(false);
	let formattedType;
	let text;
	switch (type) {
		case REACT_APP_MONTHLY_PLAN_ID:
			formattedType = 'Monthly';
			text = `$${REACT_APP_MONTHLY_AMOUNT}/month`;
			break;
		case REACT_APP_ANNUAL_PLAN_ID:
			formattedType = 'Annual';
			text = `$${REACT_APP_ANNUAL_AMOUNT}/year`;
			break;
		default:
			break;
	}

	return (
		<Box
			textAlign="center"
			py={2}
			border={1}
			borderRadius={16}
			boxShadow={hover ? 0 : 1}
			onMouseEnter={() => selectedPayment !== type && setHover(true)}
			onMouseLeave={() => selectedPayment !== type && setHover(false)}
			bgcolor={selectedPayment === type ? 'primary.main' : 'grey.300'}
			color={selectedPayment === type ? 'text.primary' : 'text.disabled'}
			style={{ 'cursor':'pointer' }}
			minWidth="35%"
			onClick={() => onClick(type)}
		>
			<Typography variant="h4">{formattedType}</Typography>
			<Typography variant="subtitle1">{text}</Typography>
		</Box>
	);
};

PaymentOption.propTypes = {
	type: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	selectedPayment: PropTypes.string.isRequired,
};

const PaymentText = ({ type }) => {
	if (type === null) {
		return null;
	}

	let amount, billingLength;
	switch (type) {
		case REACT_APP_MONTHLY_PLAN_ID:
			amount = REACT_APP_MONTHLY_AMOUNT;
			billingLength = 30;
			break;
		case REACT_APP_ANNUAL_PLAN_ID:
			amount = REACT_APP_ANNUAL_AMOUNT;
			billingLength = 365;
			break;
		default:
			return 'Unknown payment type';
	}

	let firstChargeDate = new Date();
	firstChargeDate.setDate(firstChargeDate.getDate() + parseInt(REACT_APP_TRIAL_DAYS));
	firstChargeDate = firstChargeDate.toDateString();

	return (
		<>
			<Box pb={1}>
				<Typography variant="body2" component="span">Free for </Typography>
				<Typography variant="body1" component="span" color="primary">{REACT_APP_TRIAL_DAYS} </Typography>
				<Typography variant="body2" component="span">days then first bill of </Typography>
				<Typography variant="body1" component="span" color="primary">${amount} </Typography>
				<Typography variant="body2" component="span">will be charged on </Typography>
				<Typography variant="body1" component="span" color="primary">{firstChargeDate} </Typography>
				<Typography variant="body2" component="span">then every </Typography>
				<Typography variant="body1" component="span" color="primary">{billingLength} </Typography>
				<Typography variant="body2" component="span">days</Typography>
			</Box>
			<Box py={1}>
				<Typography variant='h5' color='primary'>No commitments. Cancel anytime.</Typography>
			</Box>
		</>
	);
};

PaymentText.propTypes = {
	type: PropTypes.string.isRequired,
};

export default Payment;
