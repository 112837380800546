import React, { useState } from 'react';
import { Button, TextField, InputAdornment, Box, Input } from '@material-ui/core';
import {
	Search as SearchIcon,
	Clear as ClearIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const SEARCH_TIMEOUT = 500;

const SearchBar = ({ isSmallScreen, fullWidth, onExpandSearchBar }) => {
	const history = useHistory();
	const [ timeout, setSearchTimeout ] = useState(null);

	const search = e => {
		//clear timeout
		if (timeout) {
			clearTimeout(timeout);
		}

		//capture search value
		const value = e.target.value;
		if (value) {
			//search for given value
			setSearchTimeout(setTimeout(() => {
				history.push(`/videos?search=${value}`);
			}, SEARCH_TIMEOUT));
		}
	};

	if (fullWidth) {
		return (
			<Box mx={1} width="100%">
				<TextField
					fullWidth
					placeholder="Search"
					onChange={search}
					InputProps={{
						startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
						endAdornment: <InputAdornment position="end" onClick={() => onExpandSearchBar(false)}><ClearIcon/></InputAdornment>,
					}}
				/>
			</Box>
		);
	}

	if (isSmallScreen) {
		return (
			<Box ml={0}>
				<Button onClick={() => onExpandSearchBar(true)}>
					<SearchIcon/>
				</Button>
			</Box>
		);
	}

	return (
		<Box ml={2} minWidth={300} p={2} border={1} borderRadius={5} borderColor="black" color="black">
			<Input
				placeholder="Search"
				fullWidth
				onChange={search}
				startAdornment=<InputAdornment position="start"><SearchIcon/></InputAdornment>
			/>
		</Box>
	);
};

SearchBar.propTypes = {
	isSmallScreen: PropTypes.bool.isRequired,
	fullWidth: PropTypes.bool,
	onExpandSearchBar: PropTypes.func.isRequired,
};

export default SearchBar;
