import React, { useState, useEffect } from 'react';
import {
	AppBar,
	Toolbar,
	Box,
	IconButton,
	Button,
	Link,
	Tooltip,
} from '@material-ui/core';
import {
	Menu as MenuIcon,
	AccountBox as AccountBoxIcon,
} from '@material-ui/icons';
import { isMobile, isMobileSafari } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ShopModal from '../Public/Shop/ShopModal';
import appStoreLogo from '../images/App_Store_Badge.svg';
import googlePlayLogo from '../images/google-play-badge.png';
import SearchBar from './SearchBar';

const HEIGHT = 60;

const Header = ({ toggleDrawer }) => {
	const history = useHistory();
	const [ expandSearchBar, setExpandSearchBar ] = useState(false);
	const [ width, setWidth ] = useState(window.innerWidth);

	useEffect(() => {
		document.body.onresize = () => {
			setWidth(window.innerWidth);
		};
	}, [ setWidth ]);

	const body = expandSearchBar ? (
		<SearchBar isSmallScreen={isMobile || width < 850} fullWidth onExpandSearchBar={setExpandSearchBar}/>
	) : (
		<>
			<Box display="flex" alignItems="center">
				<IconButton onClick={toggleDrawer}>
					<MenuIcon/>
				</IconButton>
				<img
					className="clickable"
					alt="KidTube Logo"
					src={`/${isMobile ? 'kt' : 'logo_kidtube_white_text'}.png`}
					height={HEIGHT}
					onClick={()=>history.push('/')}
				/>
				<SearchBar isSmallScreen={isMobile || width < 850} onExpandSearchBar={setExpandSearchBar}/>
			</Box>
			<Box display="flex" alignItems="center">
				{!isMobileSafari && <Box mr={1}>
					<Link target="_blank" rel="noopener" href="https://apps.apple.com/us/app/kidtube-com/id1492529380?ls=1">
						<img src={appStoreLogo} alt="App Store link" width={isMobile ? '80px' : '120px'}/>
					</Link>
					<Link target="_blank" rel="noopener" href="https://play.google.com/store/apps/details?id=com.kidtube.mobile">
						<img src={googlePlayLogo} alt="Google Play Store link" width={isMobile ? '80px' : '120px'}/>
					</Link>
				</Box>}
				<ShopModal />
				<Tooltip title="Profile">
					<Button onClick={()=>history.push('/profile')}>
						<AccountBoxIcon/>
					</Button>
				</Tooltip>
			</Box>
		</>
	);

	return (
		<AppBar position="fixed">
			<Toolbar disableGutters>
				<Box width={1} height={HEIGHT} display="flex" alignItems="center" justifyContent="space-between" p={1}>
					{body}
				</Box>
			</Toolbar>
		</AppBar>
	);
};

Header.propTypes = {
	toggleDrawer: PropTypes.func.isRequired,
};

export default Header;
