import React from 'react';
import { Box, Paper, Grid, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Email, Lock } from '@material-ui/icons';

const iconStyle = {
	color: 'white',
	marginRight: '1rem',
};

const linkStyle = {
	color: 'white',
};

const Footer = () => {
	const theme = useTheme();

	return (
		<Paper style={{ backgroundColor: theme.palette.primary.main }}>
			<Box p={2}>
				<Grid container direction="row" spacing={1}>
					<Grid item xs={12} sm={6}>
						<Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" color="textSecondary">
							<Email style={iconStyle}/>
							<Link href="mailto:contactinfo@kidtube.video" style={linkStyle}>contactinfo@kidtube.video</Link>
						</Box>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" color="textSecondary">
							<Lock style={iconStyle}/>
							<Link href="/privacy.html" target="_blank" rel="noopener noreferrer" style={linkStyle}>Privacy</Link>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};

export default Footer;
