import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const Comic = ({ isMobile, comic, chapter, page }) => {
	const url = `/comics/${comic}/pages/${chapter}/${page}.jpg`;
	return (
		<Box
			p={isMobile ? 0: 2}
			bgcolor={isMobile ? '': 'primary.main'}
			border={isMobile ? 0: 3}
			borderRadius={10}
			borderColor="white"
		>
			<img src={url} alt="comic" width="100%"/>
		</Box>
	);
};

Comic.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	comic: PropTypes.string.isRequired,
	chapter: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
};

export default Comic;
