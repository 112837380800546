import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardActionArea, CardContent, Box, Typography, CardMedia } from '@material-ui/core';
import PropTypes from 'prop-types';

const { REACT_APP_MEDIA_URL } = process.env;

const VideoPreview = ({ video }) => {
	const history = useHistory();

	const handleError = e => {
		e.target.onerror = '';
		e.target.src = '/missing_thumbnail.png';
		e.target.width='100%';
		return true;
	};

	const { _id, title, key, version, thumbnail, creator } = video;
	const { username } = creator[0];

	const thumbnailNumber = thumbnail != null && thumbnail >= 0 ? String(thumbnail).padStart(7,'0') : '0000001';
	const imageSource = `${REACT_APP_MEDIA_URL}/${key}_${version}/thumbnails/${key}_${version}_tumb.${thumbnailNumber}.jpg`;
	return (
		<Card>
			<CardActionArea onClick={() => history.push(`/watch/${_id}`)}>
				<CardContent style={{ padding:'0' }}>
					<Box px={1} py={1}>
						<Typography variant="body1" noWrap>{title}</Typography>
					</Box>
					<CardMedia component="img" width="100%" image={imageSource} onError={handleError}/>
					<Box px={1} py={1}>
						<Typography variant="body1" noWrap color="primary">{username}</Typography>
					</Box>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

VideoPreview.propTypes = {
	video: PropTypes.object.isRequired,
};

export default VideoPreview;
