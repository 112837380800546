import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './Authenticated/App';
import AuthRoute from './AuthRoute';
import Verify from './Public/Verify';
import VerifyNoCode from './Public/VerifyNoCode';
import LoginPage from './Public/Login/LoginPage';
import Registration from './Public/Registration/Registration';
import theme from './theme';

toast.configure({
	position: toast.POSITION.BOTTOM_RIGHT,
});

const Authentication = () => {
	return (
		<ThemeProvider theme={theme}>
			<Router>
				<Switch>
					<Route path="/login">
						<LoginPage />
					</Route>
					<Route path="/register">
						<Registration/>
					</Route>
					<Route path='/verify/:code'>
						<Verify />
					</Route>
					<Route path='/verify'>
						<VerifyNoCode />
					</Route>
					<AuthRoute path='/'>
						<App />
					</AuthRoute>
				</Switch>
			</Router>
		</ThemeProvider>
	);
};

export default Authentication;
