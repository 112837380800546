import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const Process = ({ step }) => (
	<Box mt={2} display="flex" justifyContent="space-evenly">
		<Step number={1} step={step} name="Register"/>
		<Step number={2} step={step} name="Pay"/>
	</Box>
);

Process.propTypes = {
	step: PropTypes.number.isRequired,
};

const Step = ({ number, step, name }) => {
	const theme = useTheme();
	return (
		<Box display="flex" flexDirection="column" textAlign="center" color={step === number ? theme.palette.primary.main : 'text.disabled'}>
			<Box border={5} px={3}>
				<Typography variant="h4">{number}</Typography>
			</Box>
			<Typography variant="h6">{name}</Typography>
		</Box>
	);
};

Step.propTypes = {
	number: PropTypes.number.isRequired,
	step: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
};

export default Process;
