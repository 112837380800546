import { useState, useEffect } from 'react';
import Auth from '../Auth';
import KidTubeAPI from '../kidtubeapi';

const useCategories = sample => {
	const [ categories, setCategories ] = useState(null);

	useEffect(() => {
		KidTubeAPI.getCategories(sample)
			.then(setCategories)
			.catch(() => Auth.signOut());
	}, [ sample ]);

	return categories;
};

export default useCategories;
