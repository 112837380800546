import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Auth from '../../Auth';
import RegisterLink from '../Registration/RegisterLink';

const style = {
	backgroundColor: 'white',
};

const Login = ({ onResetPassword }) => {
	const history = useHistory();
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ error, setError ] = useState('');

	const handleEmailChange = evt => setEmail(evt.target.value.toLowerCase());

	const login = async () => {
		try {
			const { status, message } = await Auth.signIn({ email, password });

			// clear email and password
			setEmail('');
			setPassword('');
			setError('');

			switch (status) {
				case 200:
					// send to app
					history.push('/');
					break;
				case 202:
					// needs verification or payment
					switch (message.toLowerCase()) {
						case 'verify':
							history.push('/verify');
							break;
						case 'payment':
							history.push('/register?step=2');
							break;
						default:
							throw Error(`Unknown registration step needed: ${message}`);
					}
					break;
				default:
					throw Error(`Unknown status: ${status}`);
			}
		} catch (err) {
			setError(err.message || 'Incorrect email/password');
		}
	};

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<TextField
					variant="outlined"
					style={style}
					type="email"
					label="Email"
					fullWidth
					onChange={handleEmailChange}
				/>
			</Grid>
			<Grid item>
				<TextField
					variant="outlined"
					style={style}
					type="password"
					label="Password"
					fullWidth
					onChange={evt => setPassword(evt.target.value)}
				/>
			</Grid>
			{error &&
				<Grid item>
					<Typography align="center" variant="h6" color="error">{error}</Typography>
				</Grid>
			}
			<Grid item>
				<Box display="flex" flexDirection="row" justifyContent="space-between">
					<Button variant="contained" onClick={onResetPassword}>Reset Password</Button>
					<Button variant="contained" color="primary" size="large" onClick={login}>Login</Button>
				</Box>
			</Grid>
			<Grid item>
				<Box my={3} textAlign="center">
					<Typography variant="h6">New to KidTube?</Typography>
					<RegisterLink/>
				</Box>
			</Grid>
		</Grid>
	);
};

Login.propTypes = {
	onResetPassword: PropTypes.func.isRequired,
};

export default Login;
