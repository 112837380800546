import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Shop from './Shop';

const ShopModal = () => {
	const [ open, setOpen ] = useState(false);

	const close = () => {
		setOpen(false);
	};

	return (
		<>
			<IconButton onClick={() => setOpen(true)}>
				<ShoppingCartIcon /> Shop
			</IconButton>
			<Dialog
				fullWidth
				maxWidth="lg"
				open={open}
				onClose={close}
			>
				<DialogContent>
					<Shop/>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default ShopModal;
