import querystring from 'query-string';
import Auth from './Auth';

const { REACT_APP_API_URL } = process.env;

const register = async (user) => {
	let status;
	const body = JSON.stringify({ ...user, source: 'web' });
	return await fetch(`${REACT_APP_API_URL}/register`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body,
	})
		.then(r => {
			status = r.status;
			return r.json();
		})
		.then(response => {
			if (status !== 200) {
				throw Error(JSON.stringify(response));
			}
			return response;
		});
};

const authenticate = async (user) => {
	let status;
	return await fetch(`${REACT_APP_API_URL}/authenticate`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(user),
	})
		.then(r => {
			status = r.status;
			return r.json();
		})
		.then(response => ({
			status,
			...response,
		}))
		.catch(error => {
			console.log('authenticate error = ', error);
			return error;
		});
};

const resetPassword = async (email) => {
	const url = `${REACT_APP_API_URL}/reset`;
	const body = JSON.stringify({ email });
	return fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body,
	})
		.then(r => r.json());
};

const changePassword = async (old_password, new_password) => {
	let status = null;
	const url = `${REACT_APP_API_URL}/changePassword`;
	return fetch(url, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${Auth.token()}`,
		},
		body: JSON.stringify({ old_password, new_password }),
	})
		.then(r => {
			status = r.status;
			return r.json();
		})
		.then(response => {
			if (status !== 200) {
				throw new Error(response.error);
			}
		});
};

const generateVideosURL = ({ _id, creator, search, category, sample, order, limit, skip }) => {
	let url = `${REACT_APP_API_URL}/videos`;
	let data = {};

	//query videos by _id OR creator OR search OR category
	if (_id) {
		data._id = _id;
	} else if (creator) {
		data.creator = creator;
	} else if (search) {
		data.search = search;
	} else if (category) {
		data.category = category;
		data.order = order;
	}

	//always allow sample and limit and skip
	if (sample != null) {
		data.sample = sample;
	}
	if (limit != null) {
		data.limit = limit;
	}
	if (skip != null) {
		data.skip = skip;
	}

	const queryString = querystring.stringify(data);
	url += queryString ? `?${queryString}` : '';

	return url;
};

const getVideos = async (query) => {
	let status;
	return await fetch(generateVideosURL(query), {
		method: 'GET',
		headers: {
			'Authorization': `Bearer ${Auth.token()}`,
			'Content-Type': 'application/json',
		},
	})
		.then(r => {
			status = r.status;
			return r.json();
		})
		.then(response => {
			if (status !== 200) {
				throw Error(JSON.stringify(response));
			}
			return response;
		})
		.catch(error => {
			console.log('getVideos(): error: ', error);
		});
};

const completePayment = async ({ subscriptionID, type }) => {
	let status;
	return await fetch(`${REACT_APP_API_URL}/payment`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${Auth.token()}`,
		},
		body: JSON.stringify({ subscriptionID, type }),
	})
		.then(r => {
			status = r.status;
			return r.json();
		})
		.then(response => {
			if (status !== 200) {
				throw Error(JSON.stringify(response));
			}
			return response;
		})
		.catch(error => {
			console.log('API error = ', error);
			return error;
		});
};

const verify = async ({ code }) => {
	const body = JSON.stringify({ code });
	return await fetch(`${REACT_APP_API_URL}/verify`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body,
	})
		.then(r => {
			if (r.status !== 200) {
				throw new Error('Invalid code');
			}
			return r.json();
		});
};

const recordVideoClick = async (video) => {
	if (!Auth.isAuthenticated()) {
		throw Error(401);
	}

	const body = JSON.stringify({ source: 'web' });
	const url = `${REACT_APP_API_URL}/click/${video}`;

	return await fetch(url, {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${Auth.token()}`,
			'Content-Type': 'application/json',
		},
		body,
	})
		.then(r => {
			if (r.status !== 200) {
				throw Error(r.status);
			}
			return r.json();
		});
};

const watchVideo = async id => {
	const url = `${REACT_APP_API_URL}/watch/${id}`;
	const body = JSON.stringify({ source: 'web' });
	return await fetch(url, {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${Auth.token()}`,
			'Content-Type': 'application/json',
		},
		body,
	})
		.then(r => r.json())
		.catch(error => JSON.stringify(error));
};

const requestDeletion = async () => {
	const url = `${REACT_APP_API_URL}/deletion`;
	return await fetch(url, {
		method: 'PUT',
		headers: {
			'Authorization': `Bearer ${Auth.token()}`,
		},
	})
		.then(r => r.json())
		.catch(error => JSON.stringify(error));
};

const getCategories = async sample => {
	let url = `${REACT_APP_API_URL}/categories`;

	sample = parseInt(sample);
	if (!isNaN(sample)) {
		url += `?sample=${sample}`;
	}

	return await fetch(url, {
		method: 'GET',
	})
		.then(r => {
			if (r.status !== 200) {
				throw Error('unable to access categories: ', r.status);
			}
			return r.json();
		});
};

const validate = async () => {
	return await fetch(`${REACT_APP_API_URL}/validate`, {
		method: 'GET',
		headers: {
			'Authorization': `Bearer ${Auth.token()}`,
		},
	})
		.then(r => {
			if (r.status !== 200) {
				throw Error('invalid token');
			}
			return true;
		});
};

export default {
	register,
	authenticate,
	resetPassword,
	changePassword,
	getVideos,
	completePayment,
	verify,
	recordVideoClick,
	watchVideo,
	requestDeletion,
	getCategories,
	validate,
};
