import React from 'react';
import { Box, Typography, Grid, CircularProgress, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import VideoPreview from './VideoPreview';
import useVideos from './useVideos';

const VideoGroup = ({ category, limit, search, skip }) => {
	const history = useHistory();
	const order = category === '5e8260bee2c71fcbd02030fa' ? 'newest' : 'random';
	const { videos, total } = useVideos({ category: category && category._id ? category._id : category, search, order, limit, skip });

	const nextPage = () => {
		let query = queryString.parse(history.location.search);
		query.skip = skip + limit;
		if (query.skip > total - limit) {
			query.skip = total - total % limit;
		}
		history.replace(`/videos?${queryString.stringify(query)}`);
	};

	const prevPage = () => {
		let query = queryString.parse(history.location.search);
		query.skip = skip - limit;
		if (query.skip < 0) {
			query.skip = 0;
		}
		history.replace(`/videos?${queryString.stringify(query)}`);
	};

	if (!videos) {
		return (
			<Box mx="auto" pt={10} mt={10} textAlign="center">
				<CircularProgress />
			</Box>
		);
	}

	if (videos.length === 0) {
		return (
			<Box py={5} textAlign="center">
				<Typography variant="h3">Refresh to see videos</Typography>
			</Box>
		);
	}

	return (
		<Box px={1}>
			{category &&
				<Box my={2}>
					<Typography variant="h5" color="primary" style={{
						paddingBottom: '0.5rem',
						borderBottom: '3px solid rgb(238,89,52)' }}
					>{category.name}</Typography>
				</Box>
			}
			<Box py={1}>
				<Grid container spacing={1}>
					{videos && videos.map(video => (
						<Grid item key={video._id} xs={12} sm={6} md={3}>
							<VideoPreview video={video}/>
						</Grid>
					))}
				</Grid>
			</Box>
			<Box py={1} display="flex" justifyContent="center">
				{skip > 0 &&
				<Button onClick={prevPage}>Back</Button>
				}
				{total > skip + limit &&
				<Button onClick={nextPage}>More Videos</Button>
				}
			</Box>
		</Box>
	);
};

VideoGroup.propTypes = {
	category: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
	search: PropTypes.string,
	limit: PropTypes.number.isRequired,
	skip: PropTypes.number,
};

export default VideoGroup;
